<template>
  <div>
    <h1>{{ $t('views.terms.h1_title') }}</h1>
    <p>{{ $t('views.terms.p_responsible') }}</p>
    <Address />
    <h2>{{ $t('views.terms.h2_affected') }}</h2>
    <p>
      <i18n path="views.terms.p_affected">
        <template #contactForm>
          <hyperlink :href="$t('hyperlinks.contact.href')" :aria-label="$t('hyperlinks.contact.purpose')">{{
            $t('views.legal.p_contact_form_contact_form')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <h2>{{ $t('views.terms.h2_disclaimer') }}</h2>
    <h3>{{ $t('views.terms.h3_paragraph_1') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_1') }}</p>
    <h3>{{ $t('views.terms.h3_paragraph_2') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_2') }}</p>
    <h3>{{ $t('views.terms.h3_paragraph_3') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_3_1') }}</p>
    <p>{{ $t('views.terms.p_paragraph_3_2') }}</p>
    <h3>{{ $t('views.terms.h3_paragraph_4') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_4') }}</p>
    <h3>{{ $t('views.terms.h3_paragraph_4_1') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_4_1_1') }}</p>
    <p>{{ $t('views.terms.p_paragraph_4_1_2') }}</p>
    <p>{{ $t('views.terms.p_paragraph_4_1_3') }}</p>

    <h3>{{ $t('views.terms.h3_paragraph_4_2') }}</h3>
    <p>{{ $t('views.terms.p_paragraph_4_2_1') }}</p>
    <p>
      <i18n path="views.terms.p_paragraph_4_2_2">
        <template #here_1>
          <a href="https://allaboutcookies.org/">{{ $t('views.terms.p_paragraph_4_2_here') }}</a>
        </template>
      </i18n>
    </p>
    <p>{{ $t('views.terms.p_paragraph_4_2_3') }}</p>
    <ul class="mb-4">
      <li>{{ $t('views.terms.p_paragraph_4_2_3_ip-address') }}</li>
      <li>{{ $t('views.terms.p_paragraph_4_2_3_os') }}</li>
      <li>{{ $t('views.terms.p_paragraph_4_2_3_device') }}</li>
      <li>{{ $t('views.terms.p_paragraph_4_2_3_language') }}</li>
      <li>{{ $t('views.terms.p_paragraph_4_2_3_browser') }}</li>
      <li>{{ $t('views.terms.p_paragraph_4_2_3_email') }}</li>
    </ul>
    <p>
      <i18n path="views.terms.p_paragraph_4_2_4">
        <template #here_2>
          <a href="https://youradchoices.com/">{{ $t('views.terms.p_paragraph_4_2_here') }}</a>
        </template>
      </i18n>
    </p>
    <p>
      <i18n path="views.terms.p_paragraph_4_2_5">
        <template #here_3>
          <a href="https://ezoic.com/privacy/">{{ $t('views.terms.p_paragraph_4_2_here') }}</a>
        </template>
        <template #here_4>
          <a href="https://www.ezoic.com/privacy-policy/advertising-partners/">{{ $t('views.terms.p_paragraph_4_2_here') }}</a>
        </template>
      </i18n>
    </p>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
    <v-divider class="mt-6 mb-2"></v-divider>
    <p class="text-caption">
      <strong>{{ $t('views.terms.reference') }}</strong
      ><br />
      § 1 - § 4, JuraForum - URL: https://www.juraforum.de<br />
      § 4.1, Datenschutz.org - URL: https://www.datenschutz.org/google-adsense-datenschutzerklaerung<br />
      § 4.2, Ezoic - URL: https://g.ezoic.net/privacy/sudoku-knacker.de
    </p>
  </div>
</template>

<script>
import Address from '@/components/Address.vue'
import BtnHome from '@/components/btn/Home.vue'
import Hyperlink from '@/components/Hyperlink'

export default {
  name: 'Terms',
  components: {
    Address,
    BtnHome,
    Hyperlink,
  },
}
</script>

<style lang="scss"></style>
